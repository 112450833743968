<template>
	<div id="main-wrapper" class="inner-pages echoBanner">
		<div class="section banner-holder">
			<div class="title-holder t-white t-center center-div wow fadeIn">
				<h2>Economic Calendar</h2>
				<p>Global events at your fingertips</p>
			</div>
		</div>
		<div class="section bonus-trade">
			<div class="wrapper">
				<div class="title-holder wow fadeIn">
					<h5>Let our Economic Calendar acquaint you with the all the important financial events impacting global markets</h5>
				</div>
				<div class="econ-holder wow fadeIn">
					<!-- <img src="assets/images/know-img.webp" alt="AAAFx" class="know fluid-img" title="AAAFx" width="234" height="255" />
					<img src="assets/images/econ.webp" alt="AAAFx" title="AAAFx" class="fluid-img" width="1069" height="835" /> -->
					<div class="text-center">
                        <h2 class="mb-4">Economic calendar, Forex news</h2>
                        <p>
                            This is an indispensable tool for fundamental analysis of finance markert based on economic news. More than 500<br />
                            indices of the world's largest economies are based on real-time data collected from public sources.
                        </p>
                    </div>
					<div class="events-filter d-flex align-items-center justify-content-between flex-wrap mb-2">
                        <div class="d-flex align-items-center justify-content-between flex-wrap">
                            <div class="filter_one selextDuDate d-flex align-items-center">
                                <div class="customFilter-btn px-2 py-2 border-radius d-flex align-items-center" @click="showCalendar     = 'true'">
                                    <a class="d-flex" href="javascript:void(0)" @click.prevent="DateChanged('1')" v-if="!showCalendar">
                                        <vue-feather size="16px" type="chevron-left"></vue-feather> 
                                    </a>
                                    <VueDatePicker 
                                        :range="true"
                                        v-model="date"
                                        :multiCalendars="true"
                                        inline
                                        :format="'dd mmmm yyyy'"
                                        autoApply
                                        :multiCalendarsSolo="true"
                                        :enableTimePicker="false"
                                        ref="datepicker"
                                        @update:modelValue="dateChange"
                                        v-if="showCalendar"
                                        v-click-outside="hidePopup"
                                    />
                                    <p class="mx-2" v-else>{{dateformat()}}</p>
                                    <!-- <VueDatePicker v-model="date" placeholder="Select Date" ref="datepicker" range-separator="To" :format="'dd MMMM yyyy'" :shortcuts="shortcuts" :lang="lang" :range="true" :disabled-date="notAfterToday" @update:modelValue="dateChange" :editable="false" @focus="datepickerOpen = true" @blur="datepickerOpen = false"  /> -->
                                    <!-- <p v-else @click="showCalendar = true" >Select Date Range</p> -->
                                    <!-- <datepicker v-model="date" placeholder="Select Date" ref="datepicker" range-separator="To" :format="'DD MMMM YYYY'" :shortcuts="shortcuts" :lang="lang" :range="true" :disabled-date="notAfterToday" @change="dateChange()" :editable="false" @focus="datepickerOpen = true" @blur="datepickerOpen = false"></datepicker> -->
                                    <a class="d-flex" href="javascript:void(0)" @click.prevent="DateChanged('3')" v-if="!showCalendar">
                                        <vue-feather size="16px" type="chevron-right"></vue-feather> 
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="search_five">
                            <div class="upNext d-flex align-items-center">
                                <a class="customFilter-btn mr-2 px-3 py-2 border-radius d-flex align-items-center" href="#" @click.prevent="upNext()"> 
                                    <vue-feather class="left triangle" size="14px" type="triangle"></vue-feather> Up next </a>
                                <div class="flagFilter">
                                    <a @click.prevent="currencyFilter = !currencyFilter" :class="(currencyFilter)?'active':''" class="customFilter-btn padAus border-radius d-flex currencyFilter" href="#">
                                        <vue-feather size="16px" type="filter"></vue-feather>
                                    </a>
                                    <div :class="(currencyFilter)?'active':''" class="animateDorp mt-2 currencyFilter" style="z-index: 2">
                                        <div class="filterTitle d-flex align-items-center justify-content-between mb-3">
                                            <h3 class="d-flex align-items-center mb-0">Country <a class="green seleClear" href="#" @click.prevent="selectAll()">Select All</a>/ <a class="red seleClear" href="#" @click.prevent="countries = []">Clear</a></h3>
                                            <div class="filterTab">
                                                <a class="fitSelect active" href="#">My Filter</a>
                                                <a class="fitSelect ml-2" href="#">Default</a>
                                            </div>
                                        </div>
                                        <div class="scrollbar inDropdown" v-if="store.countrylist && store.countrylist.data && (store.countrylist.data !='' || store.countrylist.data != null)">
                                            <label class="custom-check-box" v-for="Country,key in store.countrylist.data" :key="key">
                                                <img  width="20" height="20" v-if="Country.name !== undefined " :src="'assets/images/country_images/'+Country.name.toLowerCase()+'.webp'" alt="Icon" > 
                                                <p v-else>-</p>
                                                {{Country.name}}
                                                <input type="checkbox" :value="Country.name" v-model="countries">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <div class="sndfil mt-2">
                                            <h3 class="d-flex align-items-center mb-0">Impact</h3>
                                            <label class="custom-check-box"> All
                                                <input type="radio" value="1" v-model="priority">
                                                <span class="checkmark"></span>
                                            </label>
                                            <label class="custom-check-box"> Medium
                                                <input type="radio" value="2" v-model="priority">
                                                <span class="checkmark"></span>
                                            </label>
                                            <label class="custom-check-box"> High
                                                <input type="radio" value="3" v-model="priority">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <div class="saveSetting d-flex align-items-center justify-content-between mt-3 pt-3 border-top">
                                            <label class="custom-check-box w-50">Save setting in my filter
                                                <input type="checkbox" checked="checked">
                                                <span class="checkmark"></span>
                                            </label>
                                            <a class="cr-btn w-auto hvr-sweep-to-top greenBG" href="#" @click="Countrywiselisting(countries)" v-if="priority == '1'">Apply Filter</a>
                                            <a class="cr-btn w-auto hvr-sweep-to-top greenBG" href="#" @click="listing()" v-else-if="priority == '2' || priority == '3'">Apply Filter</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
					<div class="table-holder wow fadeInRight home-table mt-4">
                        <table class="table economicCalendar">
                            <thead>
                                <tr>
                                    <th scope="col">Time</th>
                                    <th scope="col">Country</th>
                                    <th scope="col">Event</th>
                                    <th scope="col">Impact</th>
                                    <th scope="col">Actual</th>
                                    <th scope="col">Forecast</th>
                                    <th scope="col">Previous</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="store.loading">
                                    <td class="bg-transparent" colspan="7">
                                        <div class="row loader-row">
                                            <img width="50" height="50" src="assets/images/loaderimage.gif" class="data_currency_icon loader--icon">
                                        </div>
                                    </td>
                                    
                                </tr>
                                <tr v-else v-for="(list,key,index) in CurrentEventList" :index="index" :key="key">
                                    <td colspan="7" class="p-0">
                                        <table class="table m-0">
                                            <tr>
                                                <table class="innerTable ecnomic layout-fixed" v-for="event,i in list" :index="i" :key="i">
                                                    <tr v-if="i == 0">
                                                        <td class="text-center themecolor" colspan="7">
                                                            <h3>{{eventdate(event.date)}}</h3>
                                                        </td>
                                                    </tr>
                                                    <tr class="pointer" @click="getEvent(event.symbol,event._id)">
                                                        <td>{{datetime(event.date)}}</td>
                                                        <td>
                                                            <img width="20" height="20" v-if="event.country !== undefined "  :src="'assets/images/country_images/'+event.country.toLowerCase()+'.webp'" class="data_currency_icon">
                                                            <p v-else>=</p>
                                                            {{event.country}}
                                                        </td>
                                                        <td>{{event.event}}</td>
                                                        <td>
                                                            <span :class= "priorityLabel[event.importance]" :style="'color:'+priorityLabel[event.importance]">
                                                                <vue-feather type="bar-chart"></vue-feather>
                                                            </span>
                                                        </td>
                                                        <td>{{event.actual || '-'}}</td>
                                                        <td>{{event.forecast || '-'}}</td>
                                                        <td>{{event.previous || '-'}}</td>
                                                    </tr>
                                                    <tr v-if="currentEvent == event._id">
                                                        <td class="text-center" colspan="7" >
                                                            <div class="row loader-row" v-if="!historyview[currentSymbol]">
                                                                <img width="50" height="50" src="assets/images/loaderimage.gif" class="data_currency_icon loader--icon">
                                                            </div>
                                                            <div class="row bg-info" v-else>
                                                                <div class="col-md-5">
                                                                    <div class="mt-2" v-if="historyview[currentSymbol].lastnews && Array.isArray(historyview[currentSymbol].lastnews) && historyview[currentSymbol].lastnews[0]">
                                                                        <h2 class="mb-2"><strong>Title:</strong> {{historyview[currentSymbol].lastnews[0].title}} </h2>
                                                                        <p class="mb-2"><strong>Category:</strong> {{historyview[currentSymbol].lastnews[0].category}} </p>
                                                                        <p><strong>Description:</strong> {{historyview[currentSymbol].lastnews[0].description}} </p>
                                                                    </div>
                                                                    <p class="mt-2"><strong>Definition:</strong> {{historyview[currentSymbol].definition}}</p>
                                                                </div>
                                                                <div class="col-md-7" v-if="historyview[currentSymbol].chartdata && historyview[currentSymbol].chartdata.length > 0">
                                                                    <barChart :chart_data="historyview[currentSymbol].chartdata"  />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    import barChart from './barchart.vue'
	import moment from "moment";
	import $ from 'jquery'
	import {usePiniaStore} from "../store/pinia-store.js"
	export default {
		name: "economic-calendar",
		setup(){
			const store= usePiniaStore();
			return {store};
		},
		data() {
			return {
                showCalendar:false,
                CurrentEventList:[],
                selectcountrymessage : '',
                responseData : [],
                countries:[],
                filterdata :[],
                upnextDate: false,
                countriesList:[],
                eventlist:{},

                historyview:[],
                currentEvent: '',
                currentSymbol: '',

				priorityLabel: {"1": "yellow", "2": "orange", "3": "red"},
				timeFilter : false,
                currencyFilter : false,
				pageLimit: "15",
				search: "",
				page: 1,
				date:[Date.now(),new Date(Date.now() + 15*24 * 3600 * 1000)],
				priority: 1,
				timezone :[
                    {offset: 720 ,text:'UTC -12', val:'-12'},
                    {offset: 660 ,text:'UTC -11', val:'-11'},
                    {offset: 600 ,text:'UTC -10', val:'-10'},
                    {offset: 540 ,text:'UTC -9', val:'-9'},
                    {offset: 480 ,text:'UTC -8', val:'-8'},
                    {offset: 420 ,text:'UTC -7', val:'-7'},
                    {offset: 360 ,text:'UTC -6', val:'-6'},
                    {offset: 300 ,text:'UTC -5', val:'-5'},
                    {offset: 240 ,text:'UTC -4', val:'-4'},
                    {offset: 180 ,text:'UTC -3', val:'-3'},
                    {offset: 120 ,text:'UTC -2', val:'-2'},
                    {offset: 60 ,text:'UTC -1', val:'-1'},
                    {offset: 0 ,text:'UTC', val:'0'},
                    {offset: -60 ,text:'UTC +1', val:'+1'},
                    {offset: -120 ,text:'UTC +2', val:'+2'},
                    {offset: -180 ,text:'UTC +3', val:'+3'},
                    {offset: -210 ,text:'UTC +3:30', val:'+3.5'},
                    {offset: -240 ,text:'UTC +4', val:'+4'},
                    {offset: -270 ,text:'UTC +4:30', val:'+4.5'},
                    {offset: -300 ,text:'UTC +5', val:'+5'},
                    {offset: -330 ,text:'UTC +5:30', val:'+5.5'},
                    {offset: -345 ,text:'UTC +5:45', val:'+5.75'},
                    {offset: -360 ,text:'UTC +6', val:'+6'},
                    {offset: -420 ,text:'UTC +7', val:'+7'},
                    {offset: -480 ,text:'UTC +8', val:'+8'},
                    {offset: -540 ,text:'UTC +9', val:'+9'},
                    {offset: -570 ,text:'UTC +9:30', val:'+9.5'},
                    {offset: -600 ,text:'UTC +10', val:'+10'},
                    {offset: -630 ,text:'UTC +10:30', val:'+10.5'},
                    {offset: -660 ,text:'UTC +11', val:'+11'},
                    {offset: -720 ,text:'UTC +12', val:'+12'},
                    {offset: -780 ,text:'UTC +13', val:'+13'},
                    {offset: -840 ,text:'UTC +14', val:'+14'},
                ],
				datepickerOpen: false,
				shortcuts:[
                    { text: 'Prev 15 Days', start: new Date(Date.now() - 15*24 * 3600 * 1000), end: new Date() },
                    { text: 'Prev 30 Days', start: new Date(Date.now() - 30*24 * 3600 * 1000), end: new Date() },
                    { text: 'Next 15 Days', start: new Date(), end: new Date(Date.now() + 15*24 * 3600 * 1000) },
                    { text: 'Next 30 Days', start: new Date(), end: new Date(Date.now() + 30*24 * 3600 * 1000) },
                ],
				lang: {
                    formatLocale: {
                        firstDayOfWeek: 1,
                    },
                    monthBeforeYear: false,
                },
				timeFormat: 'am-pm',
			};
		},
		mounted(){
			let _that =  this
			$(document).on('click', function (event) {
				if (!$(event.target).closest('.timeFilter').length) {
					_that.timeFilter = false
				}
				if (!$(event.target).closest('.currencyFilter').length) {
					_that.currencyFilter = false
				}
			});

        },
		components: {
            barChart
		},
		methods: {
            hidePopup() {
                this.showCalendar = false;
            },
            dateformat() {
                let selectedrange = moment(this.date[0]).format("DD-MM-YYYY")+" To "+moment(this.date[1]).format("DD-MM-YYYY")
                return selectedrange;
            },
            dateChange(){
				if(this.date[0],this.date[0]){
                    this.showCalendar = false
					this.listing()
				}
			},
            selectAll(){
                this.countries = this.countriesList.map(val =>{
                    return val.name
                })
            },
            Countrywiselisting(getcountry){     
                if(getcountry.length > 0 && this.store.EventList.length > 0){
                    this.currencyFilter = false
                    let auto_chartist = {};

                    for(let i=0; i<=getcountry.length-1; i++){
                        for(let j=0; j<=this.store.EventList.length-1; j++){
                            if(this.store.EventList[j].country.trim(" ").toLowerCase() == getcountry[i].trim(" ").toLowerCase()){
                                this.filterdata.push(this.store.EventList[j])
                            }
                        }
                        if(i == getcountry.length-1){
                            if(this.filterdata.length > 0){
                                this.filter_no_record = false
                                for(let k=0 ; k<=this.filterdata.length-1 ; k++){
                                    let d = new Date(this.filterdata[k].date);
                                    const eventDate = moment(d).format("YYYY-MM-DD")
                                    if(this.upnextDate){
                                        if(Date.parse(this.filterdata[k].date) < Date.now().toString()){
                                            continue;
                                        }
                                    }
                                    if(auto_chartist[eventDate]){
                                        auto_chartist[eventDate].push(this.filterdata[k])

                                    }else{
                                        auto_chartist[eventDate] = []
                                        auto_chartist[eventDate].push(this.filterdata[k])

                                    }
                                    if(k == this.filterdata.length-1){
                                        this.CurrentEventList= auto_chartist
                                        if(this.upnextDate){
                                            this.upnextDate = false
                                        }
                                    }
                                }
                            }else{
                                this.filter_no_record = true
                            }
                        }
                    }                    
                }else if(getcountry.length ==  0){
                    this.selectcountrymessage = 'Please select the Country'
                }
            },
            upNext(){
                this.upnextDate = true
                this.date = [Date.now(),new Date(Date.now() + 15*24 * 3600 * 1000)]
                this.eventIndex = ''
                this.listing()
            },
			getEvent(symbol,id){
                if(symbol != '' && id != this.currentEvent){
                    this.currentEvent = id;
                    this.currentSymbol = symbol;
                    let inputJson = {
                        Symbol: symbol,
                    }
                    if(!this.historyview[this.currentSymbol]){
                        this.store.eventhistory(inputJson).then(res => {
                            this.historyview[this.currentSymbol] = res.data.data
                            return this.eventlist;
                        }).catch(e=>{
                            console.log("errro",e)
                            this.currentEvent = '';
                            this.currentSymbol = '';
                        })
                    }
                }else{
                    this.currentEvent = '';
                    this.currentSymbol = '';
                }
            },
			autoTimeChange(){
                if(this.autoTime === true){
                    let time = this.timezone.filter(list=>list.offset == new Date().getTimezoneOffset())
                    this.offset = (time.length)?time[0].val:''
                    this.timeFormat = 'am-pm'
                }
            },
			eventdate(value){
				let date= moment(value).format("dddd, DD MMMM YYYY");
				return date;
			},
			datetime(value){
				let time= moment(value).format("hh:mm a");
				return time;
			},
			Getdate(key,offset,timeFormat){
				let format = 'hh:mm a'
				if(timeFormat == '24hrs') format = 'HH:mm'
				if(timeFormat == 'am-pm') format = 'hh:mm a'
				if(offset == ''){
					return moment(key).format(format)
				}else{
					let d = new Date(key);
					let utc = d.getTime() + (d.getTimezoneOffset() * 60000);
					let nd = new Date(utc + (3600000*offset));
					return moment(nd).format(format)
				}
			},
			notAfterToday(date) {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                return date.getTime() < today.getTime();
            },
			DateChanged(val){
                let d1 = new Date()
                let d2 = new Date()
                if(this.date[0]) d1 = new Date(this.date[0]);
                if(this.date[1]) d2 = new Date(this.date[1]);
                if(val == '1'){
                    let date0 = new Date(d1 - 15*24 * 3600 * 1000)
                    let date1 = d1
                    if(moment(d1, 'MM/DD/YYYY').isBefore(moment())){
                        return
                    }
                    else if(moment(date0, 'MM/DD/YYYY').isBefore(moment())){
                        this.date = [Date.now(),new Date(Date.now() + 15*24 * 3600 * 1000)]
                    }
                    else{
                        this.date = [date0,date1]
                    }
                }
                else if(val == '3'){
                    let date2 = d2
                    let date3 = new Date(d2.getTime()+(15*24*60*60*1000))
                    this.date = [date2,date3]
                }
                // this.listing()
            },
			listing() {
				let fromdate = moment(this.date[0]).format("YYYY-MM-DD");
				let todate = moment(this.date[1]).format("YYYY-MM-DD");
				let inputJson = {
					filter: { from: fromdate.toString(), to: todate.toString(), priority: this.priority },
					sort: [],
					search: this.search,
					page: this.page,
					perPage: parseInt(this.pageLimit),
					offset: new Date().getTimezoneOffset(),
				};
				this.store.EconomicCalendarList(inputJson).then(res=>{
                    this.CurrentEventList= res
                })
			},
		},
		created() {
			this.listing();
            this.store.getCountryList();
		},
	};
</script>